export const trackNavigation = () => {
  if (typeof window.tC.event.arrowCarrousel === "function") {
    window.tC.event.arrowCarrousel(this, {});
  }
};

export const trackLayoutChange = () => {
  if (typeof window.tC.event.disposalChange === "function") {
    window.tC.event.disposalChange(this, {});
  }
};
