import React, { useState, useEffect, useContext } from "react";

import { Visible } from "react-awesome-styled-grid";

import PageContext from "src/stores/Page";
import HorizontalList from "./HorizontalList";
import VerticalList from "./VerticalList";

const CategoryList = (props) => {
  const {
    transparentHeader: [, setTransparentHeader],
  } = useContext(PageContext);

  // Hack to ensure <Visible> properly renders in production
  const [loaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  });
  // End of hack

  useEffect(() => {
    setTransparentHeader(true);

    return () => {
      setTransparentHeader(false);
    };
  }, []);

  return (
    loaded && (
      <>
        <Visible xs sm md>
          <VerticalList {...props} />
        </Visible>
        <Visible lg xl>
          <HorizontalList {...props} />
        </Visible>
      </>
    )
  );
};

CategoryList.propTypes = {};

export default CategoryList;
