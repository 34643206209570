import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Picture, { FITS } from "src/atoms/Picture";
import { LabelL, SubHeaderM } from "src/atoms/Typography";
import { colors, breakpoints, rem, lineHeight } from "src/styles/variables";

const INNER_SPACING = 30;

const Overview = React.memo(
  ({ title, description, image, slug, onClick = (v) => v }) => (
    <Container
      href={slug}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <StyledPicture
        imgStyle={{
          objectPosition: "var(--object-position)",
        }}
        fit={FITS.contain}
        small={image}
      />
      <Copy>
        <Title>{title}</Title>
        <Desc as="h2">{description}</Desc>
      </Copy>
    </Container>
  )
);

Overview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.instanceOf(Object).isRequired,
  slug: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${() => `${INNER_SPACING}px 0`};
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;

  @media (min-aspect-ratio: 1/1) {
    flex-direction: row-reverse;
    padding: ${() => `${INNER_SPACING}px`};
  }
`;

const StyledPicture = styled(Picture)`
  --object-position: center bottom;
  width: 100%;
  max-height: 200px;

  @media (min-aspect-ratio: 1/1) {
    height: 100%;
    max-height: ${() => `calc(33vh - ${INNER_SPACING * 2}px)`};
    --object-position: right center;
  }
`;

const Copy = styled.div`
  padding: ${() => `${INNER_SPACING}px`};
  padding-bottom: 0;

  @media (min-aspect-ratio: 1/1) {
    padding: 0;
    padding-right: ${() => `${INNER_SPACING}px`};
  }
`;

const Title = styled(LabelL)`
  color: ${colors.activiaGreen};

  @media screen and (max-width: ${breakpoints.sm}rem) {
    font-size: ${rem(15)};
    line-height: ${lineHeight(15, 25)};
  }
`;

const Desc = styled(SubHeaderM)`
  @media screen and (max-width: ${breakpoints.sm}rem) {
    font-size: ${rem(20)};
    line-height: ${lineHeight(20, 27)};
  }
`;

export default Overview;
