import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <rect x={6} y={6} width={9} height={9} rx={2} fill="#02BA44" />
    <rect x={17} y={6} width={9} height={9} rx={2} fill="#02BA44" />
    <rect x={6} y={17} width={9} height={9} rx={2} fill="#02BA44" />
    <rect x={17} y={17} width={9} height={9} rx={2} fill="#02BA44" />
  </svg>
));
