import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import PageContext from "src/stores/Page";
import { AppContext } from "src/layouts/AppState";

import { colors, icons } from "src/styles/variables";
import FloatingButton from "src/molecules/FloatingButton";
import ArrowLeft from "src/atoms/Vectors/Arrows/ArrowLeft";
import ArrowRight from "src/atoms/Vectors/Arrows/ArrowRight";
import ListItem from "src/molecules/ProductCategories/ListItem";
import { trackNavigation } from "./trackEvents";

const ANIMATION_DURATION = 750;

const HorizontalList = ({
  categories,
  selectCategory,
  shouldAnimate,
  hasInvertedSizes,
}) => {
  const [activeCategory, setActiveCategory] = useState(undefined);
  const [scrollPosition, setScrollPosition] = useState(undefined);
  const [showArrows, setShowArrows] = useState([false, true]);
  const { productsScrollPosition, setProductsScrollPosition } = useContext(
    AppContext
  );
  const {
    visibleHeader: [, setVisibleHeader],
  } = useContext(PageContext);

  const hasOnlyTwoCategories = (categories || []).length < 3;

  const outer = React.createRef();
  const inner = React.createRef();

  const scrollTo = (x, constrain = true, navigatingOut = false) => {
    const max = inner.current.clientWidth - outer.current.clientWidth;
    const constrained = constrain ? Math.min(Math.max(0, x), max) : x;
    if (navigatingOut) {
      setScrollPosition(constrained);
    } else {
      setProductsScrollPosition(constrained);
      setShowArrows([constrained > 0, constrained < max]);
    }
  };

  const getCategoryElements = () => {
    const children = (inner.current || {}).children || [];
    return Reflect.apply(Array.prototype.slice, children, []);
  };

  const getCategoryOffsetLeft = (category) => {
    const categoryKey = categories.indexOf(category);
    const els = getCategoryElements();
    const categoryEl = els[categoryKey];
    const categoryOffsetLeft = categoryEl.offsetLeft;
    return categoryOffsetLeft;
  };

  const getIndexOfCategoryClosestToWindowLeft = () => {
    const els = getCategoryElements();
    const sortedEls = els // sorted by closest to left hand side (ones that are beyond left will have negative vals)
      .map((el) => {
        const { left } = el.getBoundingClientRect();
        return {
          el,
          x: Math.abs(left),
        };
      })
      .sort((a, b) => (a.x > b.x ? 1 : -1));
    const currentEl = sortedEls[0].el;
    const currentElKey = els.indexOf(currentEl);
    return currentElKey;
  };

  const slide = (direction) => {
    trackNavigation();
    const els = getCategoryElements();
    const currentElKey = getIndexOfCategoryClosestToWindowLeft();
    const nextElKey = Math.max(
      0,
      Math.min(els.length - 1, currentElKey + direction)
    );
    const nextEl = els[nextElKey];
    const nextOffsetLeft = nextEl.offsetLeft;
    scrollTo(nextOffsetLeft);
  };

  const selectCategoryAnimated = (category) => {
    const categoryOffsetLeft = getCategoryOffsetLeft(category); // this must take place before any rerendering in order to not lose the reference
    const categoryKey = categories.indexOf(category);
    scrollTo(categoryOffsetLeft, !(categoryKey < categories.length), true);
    setActiveCategory(category);
    setTimeout(() => selectCategory(category.slug), ANIMATION_DURATION);
  };

  const onKeypress = (e) => {
    const direction = {
      ArrowRight: +3,
      ArrowLeft: -3,
    }[e.code];
    direction && slide(direction);
  };

  useEffect(() => {
    window.addEventListener("keyup", onKeypress);

    return () => {
      window.removeEventListener("keyup", onKeypress);
    };
  });

  useEffect(() => {
    setVisibleHeader(true);
    if (productsScrollPosition > 0) {
      scrollTo(productsScrollPosition);
    }
  }, []);

  return (
    <Outer ref={outer} shouldAnimate={shouldAnimate}>
      {(categories || []).length > 3 && !activeCategory && (
        <>
          {showArrows[0] && (
            <LeftContainer>
              <FloatingButton translucid>
                <ArrowLeft
                  stroke={colors.white}
                  size={icons.m}
                  onClick={() => slide(-3)}
                />
              </FloatingButton>
            </LeftContainer>
          )}
          {showArrows[1] && (
            <RightContainer>
              <FloatingButton translucid>
                <ArrowRight
                  stroke={colors.white}
                  size={icons.m}
                  onClick={() => slide(3)}
                />
              </FloatingButton>
            </RightContainer>
          )}
        </>
      )}
      <Inner
        ref={inner}
        scrollPosition={scrollPosition || productsScrollPosition}
        as="section"
        role="list"
      >
        {(categories || []).map((category, key) => {
          const active =
            activeCategory && activeCategory.slug === category.slug;
          return (
            <Category
              key={key}
              active={active}
              hasOnlyTwoCategories={hasOnlyTwoCategories}
              as="article"
              role="list-item"
            >
              <ListItem
                {...category}
                as={ListItemStyled}
                href={category.slug}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleHeader(false);
                  selectCategoryAnimated(category);
                }}
                active={active}
                hasOnlyTwoCategories={hasOnlyTwoCategories}
                hasInvertedSizes={hasInvertedSizes}
              >
                {category.title}
              </ListItem>
              {active && <CategoryDetails />}
            </Category>
          );
        })}
      </Inner>
    </Outer>
  );
};

HorizontalList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectCategory: PropTypes.func.isRequired,
  shouldAnimate: PropTypes.bool,
  hasInvertedSizes: PropTypes.bool,
  hasOnlyTwoCategories: PropTypes.bool,
};

const loadAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Outer = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${loadAnimation} 0.3s cubic-bezier(0, 0, 0.12, 1);
    `}
`;
const ArrowContainer = styled.div`
  position: fixed;
  z-index: 1;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
`;
const LeftContainer = styled(ArrowContainer)`
  left: 10px;
`;
const RightContainer = styled(ArrowContainer)`
  right: 10px;
`;

const Inner = styled.div`
  position: absolute;
  transform: translateX(-${({ scrollPosition }) => scrollPosition}px);
  transition: transform ${ANIMATION_DURATION / 1000}s
    cubic-bezier(0, 0, 0.12, 1);
  z-index: 0;
  display: flex;
  height: 100%;
`;

const Category = styled.div`
  width: ${({ active, hasOnlyTwoCategories }) =>
    active ? "100vw" : hasOnlyTwoCategories ? "50vw" : "33.33333vw"};
  transition: width ${ANIMATION_DURATION / 1000}s cubic-bezier(0, 0, 0.12, 1);
  height: 100%;
  display: flex;
`;

const ListItemStyled = styled.a`
  width: ${({ active, hasOnlyTwoCategories }) =>
    active ? "66.66666vw" : hasOnlyTwoCategories ? "50vw" : "33.33333vw"};
  transition: width ${ANIMATION_DURATION / 1000}s cubic-bezier(0, 0, 0.12, 1);
  height: 100%;
  background: ${({ color }) => color};
`;

const CategoryDetails = styled.div`
  width: 33.33333vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default HorizontalList;
