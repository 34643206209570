import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import HeaderSpace from "src/organisms/Header/Spacer";
import { mediaquery, colors } from "src/styles/variables";

const Overview = ({ children }) => (
  <>
    <HeaderSpace />
    <Grid>{children}</Grid>
  </>
);

Overview.propTypes = {
  children: PropTypes.node.isRequired,
};

const Grid = styled.div`
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);

  /* TODO : check whether or not to use the aspect ratio mediaquery */
  ${mediaquery.lg(css`
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);

    & > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }

    & > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    & > *:nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }

    & > *:nth-child(4) {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    }

    & > *:nth-child(5) {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
    }

    & > *:nth-child(6) {
      -ms-grid-row: 2;
      -ms-grid-column: 3;
    }

    & > *:nth-child(7) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }

    & > *:nth-child(8) {
      -ms-grid-row: 3;
      -ms-grid-column: 2;
    }

    & > *:nth-child(9) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }

    height: 100vh;
  `)}
`;

export const GridItem = styled.div`
  height: 60vh;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.lightGrey};
  border-right: 1px solid ${colors.lightGrey};

  ${mediaquery.lg(css`
    height: auto;
  `)}
`;

export default Overview;
