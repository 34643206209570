import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <rect
      x={25}
      y={25}
      width={4}
      height={18}
      rx={2}
      transform="rotate(-180 25 25)"
      fill="#02BA44"
    />
    <rect
      x={18}
      y={25}
      width={4}
      height={18}
      rx={2}
      transform="rotate(-180 18 25)"
      fill="#02BA44"
    />
    <rect
      x={11}
      y={25}
      width={4}
      height={18}
      rx={2}
      transform="rotate(-180 11 25)"
      fill="#02BA44"
    />
  </svg>
));
