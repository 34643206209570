import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import PageContext from "src/stores/Page";

import { mediaquery } from "src/styles/variables";
import ListItem from "src/molecules/ProductCategories/ListItem";

const VerticalList = ({ categories, selectCategory, hasInvertedSizes }) => {
  const {
    visibleHeader: [, setVisibleHeader],
  } = useContext(PageContext);

  useEffect(() => {
    setVisibleHeader(true);
  }, []);
  return (
    <Outer>
      {(categories || []).map((category, key) => (
        <Category key={key}>
          <ListItem
            as={ListItemStyled}
            href={category.slug}
            {...category}
            onClick={(e) => {
              e.preventDefault();
              selectCategory(category.slug);
            }}
            hasInvertedSizes={hasInvertedSizes}
          />
        </Category>
      ))}
    </Outer>
  );
};

VerticalList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectCategory: PropTypes.func.isRequired,
  hasInvertedSizes: PropTypes.string,
};

const Outer = styled.div``;
const Category = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    /* per design; when x=375, y=435, so y=x*1.16 */
    padding-top: 116%;

    ${mediaquery.sm(css`
      /* per design; when x=768, y=341, so y=x*0.44 */
      padding-top: 44%;
    `)}
  }
`;
const ListItemStyled = styled.a`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ color }) => color};
`;

export default VerticalList;
