import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <rect
      x={25}
      y={7}
      width={4}
      height={18}
      rx={2}
      transform="rotate(90 25 7)"
      fill="#02BA44"
    />
    <rect
      x={25}
      y={14}
      width={4}
      height={18}
      rx={2}
      transform="rotate(90 25 14)"
      fill="#02BA44"
    />
    <rect
      x={25}
      y={21}
      width={4}
      height={18}
      rx={2}
      transform="rotate(90 25 21)"
      fill="#02BA44"
    />
  </svg>
));
