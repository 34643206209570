import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <rect x={7} y={7} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={14} y={7} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={21} y={7} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={7} y={14} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={14} y={14} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={21} y={14} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={7} y={21} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={14} y={21} width={4} height={4} rx={2} fill="#02BA44" />
    <rect x={21} y={21} width={4} height={4} rx={2} fill="#02BA44" />
  </svg>
));
